<template>
  <v-container fluid class="px-6">
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="12" class="mt-2">
        <h2>
          Sales Data
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="3" class="py-0 mb-5">
        <v-menu
          ref="dialogDatePicker"
          v-model="dialogDatePicker"
          :close-on-content-click="false"
          :return-value.sync="filter.dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Date Range"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.dates" range>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogDatePicker = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialogDatePicker.save(filter.dates);filterProducts(products)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card class="mt-9 mx-auto" v-else>
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="#fff"
            elevation="0"
            max-width="calc(100% - 32px)"
          >
            <v-card dark color="black">
              <v-card-text class="text-center display-1 white--text">
                ${{ totalSales }}
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title text-uppercase text-center mb-2">Full Product Sales (Pre-Fees)</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card class="mt-9 mx-auto" v-else>
          <v-sheet
            class="v-sheet--offset mx-auto"
            color="#fff"
            elevation="0"
            max-width="calc(100% - 32px)"
          >
            <v-card dark color="black">
              <v-card-text class="text-center text-uppercase display-1 white--text">
                3% + 0.30$ Per # Transaction
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title text-uppercase text-center mb-2">Credit Card & Shopify Fees</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card class="mt-9 mx-auto" v-else>
          <v-sheet
            class="v-sheet--offset mx-auto"
            elevation="10"
            max-width="calc(100% - 32px)"
          >
            <v-card dark color="black">
              <v-card-text class="text-center display-1 white--text">
                {{ totalOrders }}
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title text-uppercase text-center mb-2">Total Order Transactions</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card class="mt-9 mx-auto" v-else>
          <v-sheet
            class="v-sheet--offset mx-auto"
            elevation="10"
            max-width="calc(100% - 32px)"
          >
            <v-card dark color="black">
              <v-card-text class="text-center display-1 white--text">
                {{ totalUnits }}
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title text-uppercase text-center mb-2">Total Units Sold</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          v-if="loading"
        ></v-skeleton-loader>
        <v-card class="mt-9 mx-auto" v-else>
          <v-sheet
            class="v-sheet--offset mx-auto"
            elevation="10"
            max-width="calc(100% - 32px)"
          >
            <v-card dark color="black">
              <v-card-text class="text-center display-1 white--text">
                ${{ totalRoyaltySales }}
              </v-card-text>
            </v-card>
          </v-sheet>
          <v-card-text class="pt-0">
            <div class="title text-uppercase text-center mb-2">Billable Royalty</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-9">
      <v-col cols="12" sm="12" v-if="loading">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="t of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :key="t"
          >
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="product of products"
        :key="product.key"
      >
        <v-card
          elevation="0"
          style="
            border-top-left-radius: 50px 50px;
            border-bottom-right-radius: 50px 50px;
          "
        >
          <v-img
            class="white--text align-end"
            height="200px"
            :src="product.image"
          >
          </v-img>
          <v-card-text style="background: #fbf6f2"
            ><v-card-title class="headline pt-0 mt-0 pl-0 black--text">{{
              product.title
            }}</v-card-title>
            <h1
              class="font-weight-heavy black--text d-flex justify-space-between"
            >
              <div>
                ${{ product.amountWithRoyalty }}
              </div>
              <div class="mr-4">
                {{ product.totalOrders }}
                <span class="font-weight-regular caption black--text"
                  >orders</span
                >
              </div>
            </h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      search: "",
      products: [],
      filter: {
        dates: [moment().subtract('days', 30).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      },
      dialogDatePicker: false,
    };
  },
  async created() {
    try {
      this.loading = true;
      const productsCall = fb.functions.httpsCallable("getProducts");
      const { data: products } = await productsCall();
      this.filterProducts(products)
      this.loading = false;
    } catch (error) {
      console.log(error.message);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters(["user"]),
    totalSales: function () {
      return (this.products.reduce((acc, curr) => acc + Number(curr.amount), 0)).toFixed(2);
    },
    totalRoyaltySales: function () {
      const total = this.products.reduce((acc, curr) => {
        // Apply the 3% discount and subtract $0.30 if the order total is greater than $0
        const discountedAmount = curr.amount > 0 ? (Number(curr.amount) * 0.97) - 0.30 : curr.amount;
        return acc + discountedAmount;
      }, 0);

      // Apply the user's royalty and round to 2 decimal places
      return (total * this.user.royalty).toFixed(2);
    },
    totalOrders: function () {
      const uniqueOrderIds = this.products.reduce((acc, curr) => {
        curr.ordersByTime.forEach(order => {
          acc.add(order.OrderId);
        });
        return acc;
      }, new Set());
      return uniqueOrderIds.size;
    },
    totalUnits: function () {
      const totalQuantity = this.products.reduce((acc, curr) => {
        curr.ordersByTime.forEach(order => {
          acc += order.Quantity;
        });
        return acc;
      }, 0);

      return totalQuantity;
    },
    dateRangeText: function() {
      return this.filter.dates.join(" ~ ");
    },
  },
  methods: {
    async filterProducts(products) {
      const startDate = `${this.filter.dates[0]}T00:00:01`;
      const endDate = `${this.filter.dates[1]}T23:59:59`;
      for (const product of products) {
        const ordersByTime = product.orders.filter((o) => {
          return (
            moment(o.CreatedAt).isSameOrBefore(moment(endDate)) && moment(o.CreatedAt).isSameOrAfter(moment(startDate))
          )
        })
        product.ordersByTime = ordersByTime;
        product.amount = ordersByTime.reduce((acc, curr) => acc + Number((curr.LineItemPrice * curr.Quantity) - curr.LineItemDiscountAmount),0);
        product.amountWithRoyalty = (product.amount * this.user.royalty).toFixed(2);
        product.totalOrders = ordersByTime.length;
        product.key = `${product.id}-${product.totalOrders}-${product.amount}`
      }
      this.products = products;
    }
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
