<template>
  <v-container fluid class="px-6">
    <v-dialog v-model="dialogPayment.show" persistent :overlay="false" max-width="600px">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title class="title primary white--text mb-4 text-capitalize">Request Payment</v-card-title>
          <v-card-text>
            <p v-if="this.daysSinceLastPaymentRequest < 30">
              You have already requested payment in the last 30 days, but you can request your next payment as soon as {{nextPaymentRequestDate | moment("LL")}}.
            </p>
            <p v-else-if="availableFunds < 1">
              You do not have enough available balance to request payment.
            </p>
            <p v-else>
              The available balance of ${{this.availableFunds}} will be submitted for payment. Your payment will arrive within 30 days from the date of request.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialogPayment.show = false;"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              @click="cashout()"
              :loading="saving"
              :disabled="saving || daysSinceLastPaymentRequest < 30 || availableFunds < 1"
              >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" class="mt-2">
        <h2>
          Payments
          <v-btn color="primary" small class="ml-4" @click="dialogPayment.show = true" :disabled="saving" :loading="saving">
            <v-icon small left>wallet</v-icon> Request Payment
          </v-btn>
        </h2>
      </v-col>
      <v-col cols="12" xs="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              v-if="loading"
            ></v-skeleton-loader>
            <v-card class="mt-4 mx-auto" v-else>
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="#fff"
                elevation="0"
                max-width="calc(100% - 32px)"
              >
                <v-card dark color="black">
                  <v-card-text class="text-center display-1 white--text">
                      {{ user.royalty * 100 }}%
                  </v-card-text>
                </v-card>
              </v-sheet>
              <v-card-text class="pt-0">
                <div class="title font-weight-light mb-2">Current Royalty</div>
                <div class="subheading font-weight-light grey--text">
                  On Sales
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              v-if="loading"
            ></v-skeleton-loader>
            <v-card class="mt-4 mx-auto" v-else>
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="#fff"
                elevation="0"
                max-width="calc(100% - 32px)"
              >
                <v-card dark color="black">
                  <v-card-text class="text-center display-1 white--text">
                    ${{ availableFunds }}
                  </v-card-text>
                </v-card>
              </v-sheet>
              <v-card-text class="pt-0">
                <div class="title font-weight-light mb-2">Available Balance</div>
                <div class="subheading font-weight-light grey--text">
                  For Cash Out
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              v-if="loading"
            ></v-skeleton-loader>
            <v-card class="mt-4 mx-auto" v-else>
              <v-sheet
                class="v-sheet--offset mx-auto"
                elevation="10"
                max-width="calc(100% - 32px)"
              >
                <v-card dark color="black">
                  <v-card-text class="text-center display-1 white--text">
                    ${{ totalPaid }}
                  </v-card-text>
                </v-card>
              </v-sheet>
              <v-card-text class="pt-0">
                <div class="title font-weight-light mb-2">Total Paid</div>
                <div class="subheading font-weight-light grey--text">
                  To Date
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="payments"
          :items-per-page="30"
          :loading="loading"
        >
          <template v-slot:item.createDate="{ item }">
            {{ item.createDate.toDate() | moment("MM-DD-YYYY") }}
          </template>
          <template v-slot:item.amount="{ item }">
            ${{ item.amount }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="item.status === 'submitted' ? 'amber' : 'success'"
              class="text-capitalize"
              small
            >
              {{item.status || 'Paid'}}
            </v-chip>
          </template>
          <template v-slot:item.paidDate="{ item }">
            <span v-if="item.paidDate">{{ item.paidDate.toDate() | moment("MM-DD-YYYY") }}</span>
          </template>
          <template v-slot:item.options="{item}">
            <v-btn
              outlined
              text
              small
              :disabled="generating"
              :loading="generating"
              @click="generateInvoice(item.id)"
              >
              Invoice
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      generating: false,
      products: [],
      payments: [],
      snack: false,
      snackColor: "",
      snackText: "",
      availableFunds: 0,
      totalPaid: 0,
      daysSinceLastPaymentRequest: 30,
      nextPaymentRequestDate: new Date(),
      headers: [
        {
          text: "Create Date",
          value: "createDate",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Paid Date",
          value: "paidDate",
        },
        {
          text: "",
          value: "options",
          align: "right",
        },
      ],
      dialogPayment: {
        saving: false,
        show: false,
        action: '',
      },
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.getStats();
      await this.getPayments();
      this.loading = false;
    } catch (error) {
      console.log(error.message);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    async getStats() {
      const cashoutStatsCall = fb.functions.httpsCallable("getCashoutStats");
      const { data } = await cashoutStatsCall();
      this.availableFunds = data.availableFunds;
      this.totalPaid = data.totalPaid;
    },
    async getPayments() {
      const getPayments = fb.db.collection("payments").where("createdBy.uid", "==", this.user.uid);
      const querySnapshot = await getPayments.get();
      const data = querySnapshot.docs.map(doc => {return {id: doc.id, ...doc.data()}});
      this.payments = data.sort(function(a, b) {
        return b.createDate.seconds - a.createDate.seconds;
      });
      if (this.payments.length) {
        const lastRequestDate = new Date(this.payments[0].createDate.seconds * 1000);
        this.daysSinceLastPaymentRequest = moment().diff(moment(lastRequestDate), 'days');
        this.nextPaymentRequestDate = moment(lastRequestDate).add(this.daysSinceLastPaymentRequest + 1, 'days');
      }
    },
    async cashout() {
      try {
        this.saving = true;
        // const requestCashoutCall = fb.functions.httpsCallable("requestCashout");
        // await requestCashoutCall();
        // await this.getStats();
        // await this.getPayments();
        this.dialogPayment.show = false;
        this.saving = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully submitted payment request.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "There was an error processing payment request.";
        this.saving = false;
        this.dialogPayment.show = false;
      }
    },
    async generateInvoice(id) {
      this.generating = true;
      let url = `https://us-central1-developer-portal-fb20b.cloudfunctions.net/generateInvoice?id=${id}`;
      if (location.hostname === "localhost") {
        url = `http://localhost:5001/developer-portal-fb20b/us-central1/generateInvoice?id=${id}&webview=1`;
      }
      window.open(url, "_blank");
      this.generating = false;
    }
  },
  mounted: async function () {
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>